import './App.css';
import Home from './pages/home/index';
import { Box } from '@mui/material';

function App() {
  return (

    <Box     
      height="100%"
      width="100%"  
    >
      <Home/>
    </Box>
    
  );
}

export default App;
