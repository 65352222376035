import React from "react";
import { Box, Typography } from "@mui/material";
import Countdown from "react-countdown";
import "../../fonts/fonts.css"; 

const renderer = ({ days, hours, minutes, seconds }) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      position="absolute" 
      top="12vh" 
      /* left="50%"  */
      transform="translate(-70%, -30%)" 
      color="white"
      sx={{
        textAlign: "center",
        padding: "10px",
        fontFamily: "BODDAT, sans-serif", 
        "@media (max-width: 600px)": {
          padding: "10px",
        },
      }}
    >
      <Typography
        variant="h1"
        component="h1"
        gutterBottom
        sx={{ fontFamily: "BODDAT, sans-serif" }}
      >
        ready in:
      </Typography>
      <Typography
        variant="h4"
        sx={{ fontFamily: "BODDAT, sans-serif" }} 
      >
        {days} días {hours} horas {minutes} minutos {seconds} segundos
      </Typography>
    </Box>
  );
};

const CuentaRegresiva = ({ fechaObjetivo }) => {
  return <Countdown date={fechaObjetivo} renderer={renderer} />;
};

export default CuentaRegresiva;
